import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { AlphaFormat, PointLight, SpotLight, TorusGeometry } from 'three'
import * as bootstrap from 'bootstrap';



// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector('#bg');

// Scene
const scene = new THREE.Scene();

//Loading screen
let loading = true;
let loaderwrapper = document.querySelector(`.loaderwrapper`)
// const loadingscreen = new THREE.Scene()
// const loadingcam = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
// camera.position.y = 3
// loadingscreen.add(loadingcam)
// const loadingobject = new THREE.cube


//loading manager
const loadingManager = new THREE.LoadingManager()
loadingManager.onLoad = function() {
	
    let fullHDRatio = 1920/1080
    let currentRatio = sizes.width / sizes.height
    
    loaderwrapper.classList.add( 'fade-out' );
    // loaderwrapper.parentElement.removeChild(loaderwrapper)
    console.log(`loading complete`)
    if(Bcam){
        Bcam.aspect = window.innerWidth / window.innerHeight
        Bcam.fov = 39.76 *(0.6+fullHDRatio / currentRatio*0.4)
        Bcam.updateProjectionMatrix()
        console.log(`bcam loaded`)
        console.log(window.innerWidth, window.innerHeight)
    }

    
    // optional: remove loader from DOM via event listener
    // loadingScreen.addEventListener( 'transitionend', onTransitionEnd );
    loaderwrapper.addEventListener('transitionend', () => {
        console.log('Transition ended');
        loaderwrapper.parentElement.removeChild(loaderwrapper)
      });
    
    
} ;

// function onTransitionEnd( event ) {

// 	event.target.remove();
	
// }

// Loader
const loader = new GLTFLoader(loadingManager);
let morph;
let mixer;
let lightmixer;
let Bcam, BcamAction, BcamMixer;


// Load 3D model
loader.load ( 'office.glb', function ( gltf ) {

        const model = gltf.scene;
        scene.add( model );

        model.traverse(function (objects){
            objects.castShadow = true
            objects.receiveShadow = true
            // console.log(objects.name)
            if(objects.name == `Ape_Picture`){
                console.log(objects.name)
                
            }
                

        })
        
        const children = model.children
        children.forEach(function(child){
            // console.log(child.name)
            if(child.name ==`HangingLamp`){
                const ConeChildren = child.children
                ConeChildren.forEach(Conechild => {
                    // console.log(Conechild.name)
                    if(Conechild.name == `bulb001`){
                        Conechild.add (spotLight1)
                        const empty = new THREE.Object3D()
                        empty.position.y= -1
                        spotLight1.target = empty
                        Conechild.add (empty)
                    }
                })
            }
        })

        


        // pointLight.shadow.mapSize.width = 1024*4
        // pointLight.shadow.mapSize.height = 1024*4
        // pointLight.shadow.bias = -0.001
 

        mixer = new THREE.AnimationMixer(model);
        const clips = gltf.animations;
        // const LampClip = THREE.AnimationClip.findByName(clips,'ConeAction');
        // const LampAction = mixer.clipAction(LampClip);
        // LampAction.play();
        clips.forEach(function(clip) {
            mixer.clipAction(clip).play();
        });
        
        
        

        
    },
   
	
);

loader.load ( 'camera.glb', function ( gltf ) {

    const object = gltf.scene;
    object.children[0].add(pointLight1)
    scene.add( object );

    BcamMixer = new THREE.AnimationMixer(object);
    const clips = gltf.animations;
    const BcamClip = THREE.AnimationClip.findByName(clips,'CameraAction');
    BcamAction = BcamMixer.clipAction(BcamClip);
    BcamAction.play();
    BcamAction.paused = 1;

    //clips.forEach(function(clip) {
        //mixer.clipAction(clip).play();
    //});
    
    Bcam = gltf.cameras[0];

    // gui.add(Bcam.position, 'x').min(-5).max(5);
    // gui.add(Bcam.position, 'y').min(-5).max(5);
    // gui.add(Bcam.position, 'z').min(-5).max(5);
    
})


// loader.load ( 'lights.glb', function ( gltf ) {

//     const model = gltf.scene;
//     scene.add( model );

//     model.traverse(function (objects){
//         objects.castShadow = true
//     })
    


//     lightmixer = new THREE.AnimationMixer(model);
//     const clips = gltf.animations;
//     clips.forEach(function(clip) {
//         lightmixer.clipAction(clip).play();
//     });

// });


// Objects
// const geometry = new THREE.TorusGeometry( .7, .2, 16, 100 );


// // Materials
// const material = new THREE.MeshStandardMaterial()
// material.color = new THREE.Color(0xffff00)
// material.metalness = 0.1


// // Mesh
//  const sphere = new THREE.Mesh(geometry,material)
//  sphere.position.y=2
//  sphere.castShadow = 1
//  sphere.receiveShadow = 1
//  scene.add(sphere)



//Lights
const spotLight1 = new THREE.SpotLight(0xffd37d, 10, 10, Math.PI/2.3,0.5)
spotLight1.castShadow = true
spotLight1.position.x = 0
spotLight1.position.y = 0
spotLight1.position.z = 0
spotLight1.shadow.mapSize.width = 1024*4
spotLight1.shadow.mapSize.height = 1024*4
spotLight1.shadow.bias = -0.001
// gui.add(spotLight1.rotation, 'x').min(0).max(9);
// gui.add(spotLight1.rotation, 'y').min(0).max(9);
// gui.add(spotLight1.rotation, 'z').min(0).max(9);
// gui.add(spotLight1.position, 'x').min(-9).max(9);
// gui.add(spotLight1.position, 'y').min(-9).max(9);
// gui.add(spotLight1.position, 'z').min(-9).max(9);
scene.add(spotLight1)
// const spotHelper1 = new THREE.SpotLightHelper(spotLight1)
// scene.add(spotHelper1)

const ambienlight = new THREE.AmbientLight(0xf0f0ff,0.1)
scene.add(ambienlight)


const pointLight1 = new THREE.PointLight(0xf0f0ff,1)
pointLight1.castShadow = true
pointLight1.shadow.bias = -0.001
pointLight1.position.x = 0
pointLight1.position.y = 0.2
pointLight1.position.z = 0
// gui.add(pointLight1.position, 'x').min(-9).max(9);
// gui.add(pointLight1.position, 'y').min(-9).max(9);
// gui.add(pointLight1.position, 'z').min(-9).max(9);
scene.add(pointLight1)
// const pointHelper1 = new THREE.PointLightHelper(pointLight1)
// scene.add(pointHelper1)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

//size ratio calculation:


window.addEventListener('resize', () =>
{
    let fullHDRatio = 1920/1080
    let currentRatio = sizes.width / sizes.height
    
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = currentRatio
    camera.updateProjectionMatrix()
    if(Bcam){
        Bcam.aspect = window.innerWidth / window.innerHeight
        Bcam.updateProjectionMatrix()
        Bcam.fov = 39.76 *(0.6+fullHDRatio / currentRatio*0.4)
        Bcam.position.z = Bcam.position.z*(0.6+fullHDRatio / currentRatio*0.4)
    }

    // Update renderer
    // renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
camera.position.x = 6.1112
camera.position.y = 3.32798
camera.position.z = 0.748082
camera.rotation.x = 6
scene.add(camera)
// gui.add(camera.rotation, 'x').min(0).max(9);
// gui.add(camera.rotation, 'y').min(0).max(9);
// gui.add(camera.rotation, 'z').min(0).max(9);



// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,

    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.outputEncoding = THREE.sRGBEncoding;





function movecamera() {
    const scroll = document.body.getBoundingClientRect().top ;
    const body = document.body
    const html = document.documentElement
    const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - document.body.clientHeight;
    console.log(scroll, height)
    
    BcamAction.time= -scroll/height*2.6
    // console.log(BcamAction.time)

    BcamAction.paused = 1;

    //animate elements    
    const p1 = $(".p1").offset().top
    const p2 = $(".p2").offset().top
    const p3 = $(".p3").offset().top
    const p4 = $(".p4").offset().top
    const p5 = $(".p5").offset().top
    
    if(600-scroll >= p1){
        $(".p1").animate({
            opacity: '1',
        }),1000
    }
    if(600-scroll >= p2){
        $(".p2").animate({
            opacity: '1',
        }),1000
    }
    if(600-scroll >= p3){
        $(".p3").animate({
            opacity: '1',
        }),1000
    }
    if(600-scroll >= p4){
        $(".p4").animate({
            opacity: '1',
        }),1000
    }
    if(600-scroll >= p5){
        $(".p5").animate({
            opacity: '1',
        }),1000
    }
    
        
}
document.body.onscroll = movecamera


/**
 * Animate
 */

const clock = new THREE.Clock()

const tick = () =>
{
    
    const elapsedTime = clock.getElapsedTime();
    let fullHDRatio = 1920/1080
    let currentRatio = sizes.width / sizes.height
   
    // Update objects
    // sphere.rotation.y = .5 * elapsedTime;
    if(mixer){
        mixer.update(0.02);
    }
    if(lightmixer){
        lightmixer.update(0.02);
    }
    if(BcamMixer){
        BcamMixer.update(0.02);
    }

    // Update Orbital Controls
    // controls.update()

    // Render
    if(Bcam){
        renderer.render(scene, Bcam)
        // Bcam.fov = 39.76 *(0.6+fullHDRatio / currentRatio*0.4)
        // Bcam.aspect = document.body.clientWidth / document.body.clientHeight
        
        
    }
    else{
        renderer.render(scene, camera)
    }

    
    
    

    // Call tick again on the next frame
    //window.requestAnimationFrame(tick)

    
}

renderer.setAnimationLoop(tick);

//buttons
$(".about").click(function (){
    console.log("Clicked")
    $('html, body').animate({
        scrollTop: $(".p1").offset().top - 100
    });
})

$(".roadmap").click(function (){
    console.log("Clicked")
    $('html, body').animate({
        scrollTop: $(".p3").offset().top - 100
    });
})

$(".team").click(function (){
    console.log("Clicked")
    $('html, body').animate({
        scrollTop: $(".p5").offset().top - 100
    });
})



//   $(".tab").click(function openCity(evt, cityName) {
//     // Declare all variables
//     var i, tabcontent, tablinks;
  
//     // Get all elements with class="tabcontent" and hide them
//     tabcontent = document.getElementsByClassName("tabcontent");
//     for (i = 0; i < tabcontent.length; i++) {
//       tabcontent[i].style.display = "none";
//     }
  
//     // Get all elements with class="tablinks" and remove the class "active"
//     tablinks = document.getElementsByClassName("tablinks");
//     for (i = 0; i < tablinks.length; i++) {
//       tablinks[i].className = tablinks[i].className.replace(" active", "");
//     }
  
//     // Show the current tab, and add an "active" class to the button that opened the tab
//     document.getElementById(cityName).style.display = "block";
//     evt.currentTarget.className += " active";
//   })